import React from 'react';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import classnames from 'classnames';
import styles from './FilterDisclaimer.scss';

const FilterDisclaimer = ({ mobile }: { mobile: boolean }) => (
  <Typography
    styleAs="paragraph"
    className={classnames(styles.disclaimer, {
      [styles.mobile]: mobile,
    })}
  >
    *Please note, we take every care to ensure the product, allergen and recipe information
    displayed is correct. If you have an allergy or intolerance, please always check the product
    label before use.
  </Typography>
);
export default FilterDisclaimer;
