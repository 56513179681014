import React from 'react';
import { Group } from 'components/Search/Filters/types';

type LabelProps = Pick<Group, 'allowMultiple' | 'label' | 'options' | 'value'>;

export const FilterDropdownLabel = ({ allowMultiple, label, options, value }: LabelProps) => {
  if (allowMultiple && value?.length) {
    return (
      <>
        {label}
        <span className="sr-only">{`, ${value.length} selected`}</span>
      </>
    );
  }

  if (allowMultiple || !value) {
    return label;
  }

  const selectedOption = options.find(option => option.value === value);

  if (!selectedOption) {
    return label;
  }

  return (
    <>
      {label}
      <span className="sr-only">{`, ${selectedOption.text} selected`}</span>
    </>
  );
};
