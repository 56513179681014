import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { LabeledControl } from '@johnlewispartnership/wtr-ingredients/ingredients/LabeledControl';
import { Checkbox } from '@johnlewispartnership/wtr-ingredients/ingredients/Checkbox';
import { Radio } from '@johnlewispartnership/wtr-ingredients/ingredients/Radio';
import { optionsPropTypes, valuePropTypes } from 'components/Search/Filters/prop-types';
import { isChecked } from 'components/Search/Filters/isChecked';

import styles from './FilterModalOptions.scss';

const FilterModalOptions = memo(
  ({ allowMultiple, ariaLabel, disabled, name, onChange, options = [], value }) => (
    <ul className={styles.options} id={`${name}_listbox`} role="listbox" aria-label={ariaLabel}>
      {options.map(option => {
        const { text, value: optionValue, count } = option;

        const key = optionValue || text;
        const checked = isChecked(value, option);
        const label = `${text} ${count ? `(${count})` : ''}`;

        const control = allowMultiple ? (
          <Checkbox readOnly checked={checked} name={optionValue} />
        ) : (
          <Radio readOnly checked={checked} name={optionValue} />
        );

        return (
          <li
            aria-selected={checked}
            role="option"
            aria-label={text}
            className={styles.option}
            key={key}
          >
            <LabeledControl
              data-item-value={optionValue}
              control={control}
              disabled={disabled}
              label={label}
              onChange={() => onChange(key, !checked)}
            />
          </li>
        );
      })}
    </ul>
  ),
);

FilterModalOptions.propTypes = {
  allowMultiple: PropTypes.bool,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: optionsPropTypes,
  value: valuePropTypes,
};

FilterModalOptions.defaultProps = {
  allowMultiple: false,
  ariaLabel: undefined,
  disabled: false,
  onChange: () => {},
  options: [],
  value: undefined,
};

export default FilterModalOptions;
