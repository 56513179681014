import { getSearchQueryFromApiRequest } from 'utils/searchQuery';

import { getProductFacetValuesByFacetName } from 'redux/modules/search-and-browse/selectors';

import { CLEARFILTERS, REMOVEFILTER } from 'redux/modules/search/actions/types';

export const clearFilters =
  (facetName = undefined) =>
  (dispatch, getState) => {
    if (facetName) {
      const facetValues = getProductFacetValuesByFacetName(getState(), facetName);
      const appliedFilters = getState().search.queryParams.filterTags || [];
      const selectedFacetValues = facetValues.filter(facetValue =>
        appliedFilters.some(filter => facetValue.id === filter.id),
      );

      selectedFacetValues.map(selectedFacetValue =>
        dispatch({
          type: REMOVEFILTER,
          appliedFilter: selectedFacetValue,
        }),
      );
    } else {
      dispatch({
        type: CLEARFILTERS,
      });
    }

    return getSearchQueryFromApiRequest(getState().search.queryParams);
  };
