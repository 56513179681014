import { createSelector } from 'reselect';

import { getCategoryGroup, getProductFacets } from 'redux/modules/search-and-browse/selectors';

export const getFilterGroups = createSelector(
  [getProductFacets, getCategoryGroup],
  (facets, categoryGroup) => {
    const filterTagToOption = ({ filterTag }) => ({
      count: filterTag.count,
      text: filterTag.text,
      value: filterTag.id,
    });

    const facetToGroup = ({ allowMultiple, deselectAllOptionsLabel, filters, group, label }) => {
      const deselectOption = deselectAllOptionsLabel
        ? [{ text: deselectAllOptionsLabel, deselector: true }]
        : [];

      if (group === 'OFFER_TYPE') return null;

      // Somewhat temporary until we can use consistent language for this filter across the site, then can be fixed in facets constants file
      if (group === 'DIETARY_AND_LIFESTYLE')
        return {
          allowMultiple,
          deselectAllOptionsLabel,
          label: 'Dietary & Lifestyle',
          name: group,
          options: [...deselectOption, ...filters.map(filterTagToOption)],
          value: filters.filter(filter => filter.applied).map(filter => filter.filterTag.id),
        };

      return {
        allowMultiple,
        deselectAllOptionsLabel,
        label,
        name: group,
        options: [...deselectOption, ...filters.map(filterTagToOption)],
        value: filters.filter(filter => filter.applied).map(filter => filter.filterTag.id),
      };
    };

    const facetGroups = facets.map(facetToGroup);

    return [categoryGroup, ...facetGroups].filter(Boolean).slice(0, 3);
  },
);

export const getHasOffersGroup = createSelector([getProductFacets], facets => {
  const offerGroup = facets.find(facet => facet.group === 'OFFER_TYPE');

  return offerGroup?.filters[0];
});
