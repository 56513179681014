import React, { ReactElement } from 'react';
import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';

import FilterModalPanel from 'components/Search/Filters/FilterModalPanel';
import type { Group, Value, Groups } from 'components/Search/Filters/types';

import styles from './FilterModal.scss';
import FilterDisclaimer from '../FilterDisclaimer';

type FilterModalProps = {
  groups?: Groups;
  heading?: string;
  disabled?: boolean;
  isOpen?: boolean;
  onChange: (group: Group, value: Value) => void;
  onClear: () => void;
  onClose: () => void;
  values: {
    id: string;
    text: string;
  }[];
  offersToggle?: ReactElement;
};

const FilterModal = ({
  groups = [],
  heading = 'Filter by',
  disabled = false,
  isOpen = false,
  onChange,
  onClear,
  onClose,
  values = [],
  offersToggle,
}: FilterModalProps) => {
  return (
    <Modal
      className={styles.content}
      contentClassName={styles.body}
      contentLabel="Selected Filters"
      isOpen={isOpen}
      fullScreen
      handleModalClose={onClose}
      titleText={heading}
      buttons={
        values?.length > 0
          ? [
              {
                buttonText: 'Apply',
                theme: 'primary',
                onClick: onClose,
              },
              {
                buttonText: 'Clear all',
                closeOnClick: false,
                theme: 'secondary',
                onClick: onClear,
              },
            ]
          : [
              {
                buttonText: 'Apply',
                theme: 'primary',
                onClick: onClose,
              },
            ]
      }
      buttonOrientation="horizontal"
    >
      {offersToggle && <div className={styles.offersWrapper}>{offersToggle}</div>}
      <FilterModalPanel groups={groups} disabled={disabled} onChange={onChange} />
      <FilterDisclaimer mobile />
    </Modal>
  );
};

FilterModal.displayName = 'FilterModal';

export default FilterModal;
