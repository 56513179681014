import { CATEGORY_GROUP } from 'constants/facets';
import { getSearchQueryFromApiRequest } from 'utils/searchQuery';

import { getSearchCategory } from 'redux/modules/search/selectors';
import {
  APPLYFILTER,
  APPLY_CATEGORY_FILTER,
  REMOVEFILTER,
} from 'redux/modules/search/actions/types';

import {
  getProductFacetValueById,
  getProductFacetValuesByFacetValueId,
} from 'redux/modules/search-and-browse/selectors';

export const applyFilter =
  (facetName, facetValueId, allowGroupSiblings, stickyFilter) => (dispatch, getState) => {
    const getSearchQueryString = () => getSearchQueryFromApiRequest(getState().search.queryParams);

    const appliedFilters = getState().search.queryParams.filterTags || [];

    if (facetName === CATEGORY_GROUP) {
      const selectedCategory = getSearchCategory(getState());
      const payload = selectedCategory === facetValueId ? null : facetValueId;

      dispatch({
        type: APPLY_CATEGORY_FILTER,
        payload,
        stickyFilter,
      });

      return getSearchQueryString();
    }

    if (!allowGroupSiblings) {
      const facetValues = getProductFacetValuesByFacetValueId(getState(), facetValueId);
      const selectedFacetValues = facetValues.filter(facetValue =>
        appliedFilters.some(filter => facetValue.id === filter.id),
      );

      selectedFacetValues.forEach(selectedFacetValue =>
        dispatch({
          type: REMOVEFILTER,
          appliedFilter: selectedFacetValue,
        }),
      );
    }

    dispatch({
      type: appliedFilters.some(filter => filter.id === facetValueId) ? REMOVEFILTER : APPLYFILTER,
      appliedFilter: getProductFacetValueById(getState(), facetValueId),
      stickyFilter,
    });

    return getSearchQueryString();
  };
