import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';

import { groupsPropTypes } from 'components/Search/Filters/prop-types';
import FilterModalOptions from 'components/Search/Filters/FilterModalOptions';

import styles from './FilterModalPanel.scss';

const FilterModalPanel = pure(({ groups, disabled, onChange }) => (
  <div className={disabled ? styles.loading : ''}>
    {groups.map(group => (
      <article
        aria-labelledby={`${group.name}_panel_label`}
        className={styles.article}
        role="region"
        key={group.name}
      >
        {group.label && (
          <header className={styles.header}>
            <h4 className={styles.title} id={`${group.name}_panel_label`}>
              {group.label}
            </h4>
          </header>
        )}
        <section className={styles.group}>
          <FilterModalOptions
            allowMultiple={group.allowMultiple}
            disabled={disabled}
            name={group.name}
            onChange={(...args) => onChange(group, ...args)}
            options={group.options}
            value={group.value}
          />
        </section>
      </article>
    ))}
  </div>
));

FilterModalPanel.displayName = 'FilterModalPanel';

FilterModalPanel.propTypes = {
  disabled: PropTypes.bool,
  groups: groupsPropTypes,
  onChange: PropTypes.func,
};

export default FilterModalPanel;
