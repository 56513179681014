import PropTypes from 'prop-types';

export const optionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    count: PropTypes.number,
    text: PropTypes.string,
    value: PropTypes.string,
  }),
);

export const valuePropTypes = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.string),
  PropTypes.string,
]);

export const groupsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    allowMultiple: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    options: optionsPropTypes,
    value: valuePropTypes,
  }),
);
